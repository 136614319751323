import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Hero, AboutUs, WhatWeDo, Foundations } from "layout/Home";
import API from 'api/Api';
import Cache from "utils/cache";
import axiosInstance from 'api/axiosInstance';
import * as loader from "components/Loading/loading";

const Home1Page = () => {
	const [foundations, setFoundations] = useState([]);
	const [cacheReady, setCacheReady] = useState(false);
	const [isCacheReady, setIsCacheReady] = useState(false);

	const getProfile = async (data) => {
		const currentUrl = window.location.href;
		const apiUrl = currentUrl.includes('stage') ? 'https://stage-ob.aix-exchange.com/api/auth' : 'https://ob.aix.asia/api/auth';
		loader.showLoader();
		try {
			const response = await axiosInstance.get(apiUrl, {
				headers: {
					'Content-Type': 'application/json',
					'x-api-version': "1.4.24",
					xtoken: `${ data }`
				}
			});

			loader.hideLoader();
			setLoginUser(response.data.data);
		} catch (error) {
			loader.hideLoader();
		}
	};

	const getBalance = async (token, platform) => {
		const currentUrl = window.location.href;
		const apiUrl = currentUrl.includes('stage') ? 'https://stage-ob.aix-exchange.com/api/users/coins' : 'https://ob.aix.asia/api/users/coins';
		loader.showLoader();
		try {
			const response = await axiosInstance.get(apiUrl, {
				headers: {
					'Content-Type': 'application/json',
					'x-api-version': "1.4.24",
					xtoken: `${ token }`
				}
			});
			const usdtData = response.data.data.find(item => item.coin.symbol === "USDT");
			const dataUser = { xtoken: token, balance: usdtData.availableBalance, platform: platform };
			Cache.setData(dataUser);
			setCacheReady(true);
		} catch (error) {
			console.log(error);
		}
	};

	const getAPIInstitutions = () => {
		API.get('foundations?limit=10&page=1&filter=').then(response => {
			setFoundations(response.data.foundations);
		}).catch(error => {
			console.log(error);
		});
	};

	const setLoginUser = (userData) => {
		loader.showLoader();
		let data = {
			fullname: userData.fullName,
			email: userData.email,
			phone: userData.phone,
			user_type: userData.account_type,
			unique_id: userData.referralCode
		};

		API.post(`force-login`, data).then(response => {
			loader.hideLoader();
			Cache.setUser(response.data);
			Cache.setAccessToken(response.data.token);
		}).catch(error => {
			loader.hideLoader();
		});
	};

	useEffect(() => {
		getAPIInstitutions();
		const urlParams = new URLSearchParams(window.location.search);
		const token = decodeURIComponent(urlParams.get('xtoken'));
		const platform = urlParams.get('platform');

		if (token) {
			getProfile(token);
			getBalance(token, platform);
		} else {
			setCacheReady(true);
		}
	}, []);

	useEffect(() => {
		const cachedData = Cache.getData();
		if (cachedData === null && cacheReady) {
			window.location.reload();
		} else if (cachedData !== null && cacheReady) {
			setIsCacheReady(true);
		} else {
			setIsCacheReady(false);
		}
	}, [cacheReady]);

	return (
		<div className="flex flex-col items-center justify-start w-full bg-white-A700">
			<div className="relative w-full">
				<Header showBackButton={ false } />
			</div>
			<Hero />
			<AboutUs />
			<WhatWeDo />
			{ foundations.length > 0 && <Foundations foundations={ foundations } /> }
			<Footer />
		</div>
	);
};

export default Home1Page;
