import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Institutions } from "layout/Institutions";
import API from 'api/Api';
import 'react-loading-skeleton/dist/skeleton.css';
import { Heading } from "components";
import { useParams } from "react-router-dom";
import * as loader from "components/Loading/loading";

export default function DetailInstitutions() {
	const queryParams = useParams();
	const [charities, setCharities] = useState(null);
	const [institutions, setInstitutionsDetail] = useState(null);
	const [page, setPage] = useState(1);

	useEffect(() => {
		getAPICharities();
	}, [page]);

	useEffect(() => {
		getInstitutionsDetails();
	}, []);

	const getAPICharities = () => {
		loader.showLoader();
		API.get(`charities?limit=10&page=1&filter=&institution_id=${ queryParams.id }`)
			.then(e => {
				loader.hideLoader();
				setCharities(e.data.charities);
			})
			.catch(e => {
				loader.hideLoader();
			});
	};
	const getInstitutionsDetails = () => {
		loader.showLoader();
		API.get(`institutions?institution_id=${ queryParams.id }`)
			.then(e => {
				loader.hideLoader();
				setInstitutionsDetail(e.data.institutions);
			})
			.catch(e => {
				loader.hideLoader();
			});
	};

	const onPrevClick = () => {
		if (page > 1) {
			setPage(prevPage => prevPage - 1);
		}
	};

	const onNextClick = () => {
		setPage(prevPage => prevPage + 1);
	};
	return (
		<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
			<div className="relative w-full">
				<Header />
			</div>
			{ institutions && (
				<div className="min-h-screen-min-topbar mt-10 max-w-[940px] w-full md:w-[940px]">
					<Heading as="p" size="lg" className="mt-20 px-10">
						{ institutions[0].name }
					</Heading>
					<Heading as="p" className="!text-base !font-normal !text-blue_gray-700 px-10 mt-5" dangerouslySetInnerHTML={ { __html: institutions[0].description } } />
					{ charities && charities.length > 0 && (
						<Institutions
							institutions={ charities }
							onPrevClick={ onPrevClick }
							onNextClick={ onNextClick }
						/>
					) }
				</div>
			) }
			<Footer />
		</div>
	);
}
