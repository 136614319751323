import axios from 'axios';
import Cache from 'utils/cache';
import axiosInstance from './axiosInstance';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API = {
  get,
  post,
  put,
  delete: deleteAction,
  postMultipart,
  putMultipart,
  patch,
  deleteActionV2,
};

function get(path) {
  return axiosInstance.get(`${API_BASE_URL}/${path}`, {
    headers: authHeader(path)
  }).then(handleResponse);
}

function post(path, body) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader(path) },    
  };
  return axiosInstance.post(`${API_BASE_URL}/${path}`, JSON.stringify(body), requestOptions).then(handleResponse);
}

function postMultipart(path, body) {
  const requestOptions = {
    headers: authHeader(path),    
  };
  return axiosInstance.post(`${API_BASE_URL}/${path}`, body, requestOptions).then(handleResponse);
}

function put(path, body) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader(path) },
  };
  return axiosInstance.put(`${API_BASE_URL}/${path}`, JSON.stringify(body), requestOptions).then(handleResponse);
}

function patch(url, body) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function putMultipart(url, body) {
  const formData = new FormData();

  for (const name in body) {
    if (name === 'image') {
      formData.append(name, body[name], 'filename.png');
    } else {
      formData.append(name, body[name]);
    }
  }
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(url),
    body: formData,
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function deleteAction(url) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(url),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function deleteActionV2(url, body) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(url),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  try {
    const token = Cache.getAccessToken();
    const refreshToken = Cache.getRefreshToken();

    const isLoggedIn =
      typeof token === 'string' && token !== ''
    
    if(url === 'refresh-token'){
      return { Authorization: `Bearer ${refreshToken}` };
    }else if(isLoggedIn) {
      return { Authorization: `Bearer ${token}` };
    }
  } catch (e) {
    writeLog(e);
  }

  return {};
}

const writeLog = (e) => {
  typeof(process) !== 'undefined' && process.stderr.write(e);
}

function handleResponse(response) {
  console.log(response.status)
  // return response.text().then(text => {
  //   const data = text && JSON.parse(text);

  //   if (!response.ok) {
  //     // if ([401, 403].includes(response.status) && authService.userValue) {
  //     //   // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
  //     //   accountService.logout();
  //     // }

  //     const error = (data && data.message) || response.statusText;
  //     return Promise.reject(error);
  //   }
  //   return data;
  // });
  return response.data ? response.data : {}
}

export default API;
