import axios from 'axios'
import API from './Api'
import Cache from 'utils/cache'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // other configurations
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = config.url === "/refresh-token" ? Cache.getRefreshToken() : Cache.getAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (originalConfig.url !== "/login" && originalConfig.url !== "/refresh-token" && error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axiosInstance.post("/refresh-token");

          const { token } = rs.data.data;

          // store.dispatch('refresh-token', accessToken);
          Cache.setAccessToken(token);        

          return axiosInstance(originalConfig);
        } catch (_error) {
          if(_error.response.status === 401){
            Cache.clearLogin()
            localStorage['flash'] = 'Please re-login to continue'
            document.location = '/'
          }
          // localStorage.removeItem('user')
          
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
