import React, { useState } from "react";
import { Button, Img, Text } from "./..";
import { Link, useNavigate } from "react-router-dom";
import Cache from "utils/cache";
import { IconChevronLeft } from '@tabler/icons';

export default function Header({ showBackButton = true, ...props }) {
	const navigate = useNavigate();
	const platform = Cache.getData()?.platform;
	const backButton = platform === 'app' && showBackButton;
	const handleAIXClick = () => {
		const currentUrl = window.location.href;
		if (platform === 'app') {
			window.ReactNativeWebView.postMessage("closeWebView");
		} else {
			const redirectUrl = currentUrl.includes('stage')
				? 'https://stage-fe.aix-exchange.com/'
				: 'https://aix.asia/';

			window.location.href = redirectUrl;
		}
	};
	return (
		<header { ...props } className="flex flex-col items-center justify-start w-full fixed shadow-2xl z-10">
			<div className="flex flex-row justify-center w-full bg-white-A700 p-4 md:p-3 shadow-xs">
				<div className="flex flex-row justify-between items-center w-full mr-2 max-w-[940px]">
					<div className="flex gap-1 items-center w-[50%] md:w-[30%] h-5 md:h-10 relative">
						{ backButton && (
							<div
								onClick={ () => navigate(-1) }
							>
								<IconChevronLeft />
							</div>
						) }
						<div className="flex-1"
							onClick={ (e) => {
								navigate('/');
							} }
						>
							<Img
								src="/images/logo.jpeg"
								alt="vectorone_one"
								className="justify-center w-40 md:w-44"
							/>
						</div>
					</div>
					<div className="flex flex-row justify-between items-center gap-x-3 md:gap-x-5">
						<Link to="/contact-us">
							<Text as="p" color="text-blue_gray-800" className="!font-worksans text-sm md:!text-lg border-r pr-3 md:pr-5 border-gray-900">
								Contact
							</Text>
						</Link>

						{ Cache.isLoggedIn() ?
							<Button onClick={ handleAIXClick } size="sm" className="!bg-transparent text-[#AD872E] border-2 border-[#AD872E] rounded-xl uppercase text-sm md:text-base font-medium w-[50px] md:w-[70px] h-[30px] md:h-[35px]">
								AIX
							</Button> :
							<Button onClick={ handleAIXClick } size="sm" color="red_300" className="uppercase font-medium w-[50px] md:w-[100px] h-[30px] md:h-[40px] text-xs md:text-sm">
								Login
							</Button>
						}
					</div>
				</div>
			</div>

		</header>
	);
}
