import React from "react";
import { Img, Heading } from "../../components";
import { NumericFormat } from "react-number-format";

const Hero = ({ charity }) => {
	return (
		<div className="flex flex-col justify-center w-full">
			<Img src={ charity.image_url } alt="kidsone_one" className="w-full object-cover max-h-[300px] " />
			<div className="flex flex-col px-10 gap-y-3 mt-5">
				<Heading as="p" className="mt-1 !text-xl">
					{ charity.charity_name }
				</Heading>
				<div className="w-full h-[6px] bg-gray-200 rounded">
					<div
						className="h-full bg-blue rounded"
						style={ { width: `${ charity.progress * 100 }%` } }
					/>
				</div>
				<Heading size="sm" as="p" className="!text-base">
					<NumericFormat value={ `${ charity.nominal_current_achievement || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } /> - <NumericFormat value={ `${ charity.nominal_target || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } />
				</Heading>
			</div>
		</div>
	);
};

export default Hero;