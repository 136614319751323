import React from "react";
import { Heading } from "../../components";

const Description = ({ charity }) => {
	return (
		<div className="flex flex-col justify-start w-full px-10 gap-10 mt-5 mb-10">
			<Heading as="p" className="!text-base !font-normal !text-blue_gray-700" dangerouslySetInnerHTML={ { __html: charity.description } } />
		</div>
	);
};

export default Description;