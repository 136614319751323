import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import API from 'api/Api';
import 'react-loading-skeleton/dist/skeleton.css';
import { Button, Img, Heading } from "components";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
class FoundationsPage extends React.Component {

	constructor() {
		super();

		this.state = {
			institutions: [],
			limit: 10,
			page: 1,
			total_page: 1,
			loading: true,
		};
	}

	componentDidMount() {
		this.getAPIInstitutions();
	}

	getAPIInstitutions() {
		const { limit, page } = this.state;
		this.setState({ loading: true });

		API.get(`foundations?limit=${ limit }&page=${ page }`).then(e => {
			this.setState({ foundations: e.data.foundations, loading: false, total_page: e.data.paging.total_page }, () => {
				console.log(e);
			});
		}).catch(e => {
			this.setState({ loading: false });
			console.log(e);
		});
	}

	onPrevClick = () => {
		this.setState({ page: this.state.page - 1 }, () => {
			this.getAPIInstitutions();
		});
	};

	onNextClick = () => {
		this.setState({ page: this.state.page + 1 }, () => {
			this.getAPIInstitutions();
		});
	};
	truncateText = (text, maxWords) => {
		const words = text.split(' ');
		if (words.length > maxWords) {
			return words.slice(0, maxWords).join(' ') + '...';
		}
		return text;
	};
	render() {
		const { foundations, loading, page, total_page } = this.state;


		return (
			<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
				<div className="relative w-full">
					<Header />
				</div>
				<div className="min-h-screen-min-topbar mt-10  max-w-[940px] w-full md:w-[940px] px-10">
					{
						!loading && <Heading as="p" size="lg" className="mt-20">
							Foundations
						</Heading> }
					{
						foundations &&
						<div className="justify-center w-full gap-y-10 gap-x-5 grid-cols-1 lg:grid-cols-3 grid py-10 ">
							{
								foundations.map((item, index) => (
									<Link to={ `/foundations/${ item.id }` } className="flex flex-col justify-between w-full h-full  bg-white-A700/50" key={ index }>
										<div className="flex flex-row justify-center w-full border-gray-400 border-b-[0.5px] border-solid ">
											<Img
												src={ item.image_url }
												alt={ item.name }
												className="w-full h-[200px] object-cover"
											/>
										</div>
										<div className="flex flex-col p-3 gap-2.5">
											<Heading as="p" className="mt-1 !text-xl">
												{ item.name }
											</Heading>
											<Heading as="p" className="!text-base !font-normal !text-blue_gray-700" dangerouslySetInnerHTML={ { __html: this.truncateText(item.description, 10) } } />
										</div>
										<Link to={ `/foundations/${ item.id }` } className="flex flex-col items-center my-3">
											<Button size="sm" className="h-[40px] mb-1 uppercase font-medium w-[140px] !text-base">
												View More
											</Button>
										</Link>
									</Link>
								))
							}
						</div>
					}
				</div>

				<Footer />
			</div>
		);
	}
}

export default FoundationsPage;
