import React from "react";
import { Text, Button, Img, Heading } from "../../components";

const Hero = () => {
	return (
		<div className="relative flex flex-col items-center justify-center mt-[50px] w-full h-screen md:h-[80vh]">
			<Img
				src="/images/img_dashboard.png"
				alt="treeone_one"
				className="absolute inset-0 h-full w-full object-cover"
			/>
			<div className="absolute inset-0 bg-blue_gray-700 opacity-60"></div>
			<div className="max-w-[940px] w-full lg:w-[940px] px-10">
				<div className="relative flex flex-col items-center lg:items-start justify-center md:w-[80%] gap-10">
					<Heading size="md" as="h1" className="!text-white-A700 leading-relaxed !text-3xl md:!text-[36px] text-center lg:text-left">
						Revolutionizing Zakat Payment
						<br className="hidden lg:block" />
						{ " " } { 'with AIX Technology' }
					</Heading>
					<div className="flex flex-row justify-start items-center w-full">
						<div className="flex flex-row justify-start w-[30%] md:w-[40%]">
							<div className="h-[24px] w-[24px] z-[1] bg-white-A700 rounded-[50%]" />
							<div className="h-[2px] w-full mt-2.5 ml-[-24px] bg-white-A700" />
						</div>
						<Button
							color="white_A700"
							size="xs"
							variant="outline"
							className="ml-[-2px] tracking-[3.42px] uppercase font-medium w-full md:min-w-[289px] rounded-[16px] !text-sm"
						>
							Sedeqah Tech
						</Button>
					</div>
					<div className="flex flex-row items-center gap-[15px] mt-10">
						<Text size="s" as="p" className="!text-white-A700 text-justify">
							In a world where digitalization is reshaping the way we interact with finances,
							SedeqahTech emerges as a pioneering force, leveraging advanced Artificial
							Intelligence (AI) technology by Asia International Exchange (AIX) to redefine the
							landscape of Sedeqah contribution (Zakat, Sedeqah, Infaq & Waqf) globally.
						</Text>
					</div>
					<div className="flex flex-row justify-center lg:justify-start w-full gap-[15px]">
						<Button size="sm" color="red_300" className="h-[40px] uppercase font-medium w-full lg:w-[145px] !text-base" onClick={ () => window.scrollTo({ top: 2800, behavior: "smooth" }) }>
							Donate
						</Button>
						{/* <Button size="sm" color="white_A700" className="h-[40px] uppercase font-medium w-[145px] !text-base" onClick={() => document.location = '/institutions'}>
        Discover
      </Button> */}
					</div>
				</div>
			</div>
		</div>

	);
};

export default Hero;


